import { graphql } from "gatsby"
import React from "react"
import "lazysizes"
import ReactFullpage from '@fullpage/react-fullpage'

import "./archive.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query archiveQuery {
    allDirectusArchiveProject(sort: {fields: sort}) {
      nodes {
        directusId
        project_name
        project_url
        featured_video
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
              resize(height: 100) {
                src
              }
              resolutions(width: 640) {
                src
              }
            }
          }
        }
        project_content {
          id
          content_image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                resize(height: 100) {
                  src
                }
                resolutions(width: 640) {
                  src
                }
              }
            }
          }
          content_video
        }
      }
    }
  }
`;



const Archive = ({ data: { allDirectusArchiveProject: projects } }) => {

  const afterLoad = (origin, destination, direction) => {
    let ProjectTitle = document.querySelector('.archive__project-name')
    ProjectTitle.textContent = destination.item.title
  }
  
  return (
    <Layout>
      <SEO title="Archive | " />
      
      <div className="archive">
        <div className="archive__title">Archive</div>
        <div className="archive__project-name"></div>
    
        <div className="archive__container">
          <ReactFullpage
            licenseKey = {'2B836618-DC074E78-96D34745-69DC8258'}
            autoScrolling = { false }
            lazyLoading = { false }
            responsiveHeight= { '1480' }
            responsiveSlides= { true }
            afterLoad={ afterLoad.bind(this) }


            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  { projects.nodes.map((project) => (
                    <div key={ project.directusId } className="section fp-auto-height-responsive" title={ project.project_name }>
                  
                      <div className="slide">
                        { project.featured_image ?
                            <figure>
                              <img
                                className="lazyload"
                                src={ project.featured_image.localFile.childImageSharp.resize.src }
                                data-src={ project.featured_image.localFile.childImageSharp.original.src }
                                data-srcset={ 
                                    `${project.featured_image.localFile.childImageSharp.resolutions.src} 640w,
                                    ${project.featured_image.localFile.childImageSharp.original.src} 1920w` }
                                alt={project.project_name} />
                            </figure>
                          :
                            <div className="slide__video slide__video-featured">
                              <iframe
                                width="100%"
                                height="100%"
                                title={ project.project_name }
                                src={ `https://player.vimeo.com/video/${ project.featured_video }?autoplay=1&loop=1&autopause=0&muted=1&byline=0&title=0` }
                                frameBorder="0"
                                allow="autoplay"
                                data-autoplay></iframe>
                            </div>
                        }
                      </div>

                  { project.project_content.map((content) => 
                      <div key={ content.id } className="slide">
                        { content.content_image ?
                          <figure>
                            <img
                              className="lazyload"
                              src={ content.content_image.localFile.childImageSharp.resize.src }
                              data-src={ content.content_image.localFile.childImageSharp.original.src }
                              data-srcset={ 
                                  `${content.content_image.localFile.childImageSharp.resolutions.src} 640w,
                                  ${content.content_image.localFile.childImageSharp.original.src} 1920w` }
                              alt={project.project_name} />
                          </figure>
                        :
                          <div className="slide__video slide__video-content">
                              <iframe
                                width="100%"
                                height="100%"
                                title={ project.project_name }
                                src={ `https://player.vimeo.com/video/${ content.content_video }?autoplay=1&loop=1&autopause=0&muted=1&byline=0&title=0` }
                                frameBorder="0"
                                allow="autoplay"
                                data-autoplay></iframe>
                          </div>
                        }
                      </div>                    
                  )}
                    </div>
                  )) }
                </ReactFullpage.Wrapper>
              )
            }}
          />
        </div>
      </div>
    </Layout>
  )
}


export default Archive
